import * as React from "react"
import { Link } from "gatsby"
import "@fontsource/merriweather"

// styles
const pageStyles = {
  fontFamily: "Merriweather, serif",
  width: "auto",
  height: "auto",
  maxWidth: "768px",
  margin: "auto",
  padding: "30px 30px 30px 30px",
  color: "#d8d8d8",
  fontSize: '1.5em'
}

const paragraphStyles = {
  marginBottom: 48,
}
// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found | rarakasm</title>
      <div style={{fontSize: '6em'}}><span role="img" aria-label="thinking">🤔</span></div>
      <h1>Page not found</h1>
      <p style={paragraphStyles}>
        Nothing seems to be here. That's just life sometimes.
        <br /><br />
        <Link to="/">Take me home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
